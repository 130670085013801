<template>
	<div>
		<h3 class="green center">完善机构信息</h3>
		<div style="padding: 0.5rem 1rem">
			<form id="registerForm">
				<div class="form-group">
					<label class="col-12 col-sm-6 col-md-4 col-lg-3 control-label right">
						公司名称<span class="red">*</span>
					</label>
					<div class="col-12 col-sm-6 col-md-8 col-lg-9">
						<input
						type="text"
						class="form-control"
						name="departName"
						v-model="org.departName"
            disabled
						/>
					</div>
				</div>
				<div class="form-group">
					<label class="col-12 col-sm-6 col-md-4 col-lg-3 control-label right">
						统一社会信用代码<span class="red">*</span>
					</label>
					<div class="col-12 col-sm-6 col-md-8 col-lg-9">
						<input
						type="text"
						class="form-control"
						name="usccCode"
						v-model="org.usccCode"
						maxlength="18"
						/>
					</div>
				</div>
				<div class="form-group">
					<label class="col-12 col-sm-6 col-md-4 col-lg-3 control-label right">
						联系人
					</label>
					<div class="col-12 col-sm-6 col-md-8 col-lg-9">
						<input
						type="text"
						class="form-control"
						name="legalPerson"
						v-model="org.legalPerson"
						/>
					</div>
				</div>
				<div class="form-group">
					<label class="col-12 col-sm-6 col-md-4 col-lg-3 control-label right">
					联系电话
					</label>
					<div class="col-12 col-sm-6 col-md-8 col-lg-9">
						<input
						type="text"
						class="form-control"
						name="mobile"
						v-model="org.mobile"
						maxlength="14"
						/>
					</div>
				</div>
				<div class="form-group">
					<div class="col-6 offset-3 center" style="border-bottom: none">
						<button
						class="btn btn-primary col-5 col-sm-5 col-md-4 offset-1"
						type="submit"
						@click.prevent="orgfinish"
						>
							确定注册
						</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
import global from '@/common/global'
export default{
	data(){
		return{
			org:{
				id:"",						//主键
				departName:"",				//机构名称
				usccCode:"",				//社会统一信用代码
				legalPerson:"",				//联系人
				mobile:"",					//联系电话
			},
			isPc:false
		}
	},
	created() {
		//判断终端类型
		this.isPc = !/(Android|iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
		//获取当前机构名称及id
		let currentOrg = this.$ls.get("edCurrentOrg")
		this.org.id = currentOrg.id
		this.org.departName = currentOrg.departName
	},
	methods:{
		//完善机构信息
		orgfinish:function(){
			//后台查询
			this.$ajax({
				method:'post',
				url:'/gateway/ed/workweixin/orgfinish',
				data:this.org
			}).then(res=>{
				if(res.status=="200"){
					if(res.data.retCode=="0"){
						//org
						this.$ls.set("edCurrentOrg",res.data.org)
						//扩展信息
						if(res.data.external){
							this.$ls.set("edCurrentOrgExternal",res.data.external)
						}
						if(this.isPc){
							window.location.href= global.pcUrl
						}else{
							//跳转主页
							this.$router.push({"name":"home"})
						}
					}else{
						this.$toast({
							message: "完善机构信息出错，请稍候重试！",
							type: 'fail',
							duration: 5000
						})
					}
				}else{
					this.$toast({
						message: "完善机构信息出错，请稍候重试！",
						type: 'fail',
						duration: 5000
					})
				}
			}).catch(err=>{
				console.log(err)
			})
		}
	}
}
</script>

<style scoped>
.green.center {
  margin-top: 1rem;
  font-size: 0.65rem;
  color: #00CCB2;
  font-weight: bold;
}
.control-label {
  font-weight: bold;
}
.form-group {
  margin-top: 0.5rem;
}
.form-group > div {
  margin-top: .15rem;
  padding-bottom: 0.15rem;
  border-bottom: .013333rem #E6E6E6 solid;
}

.btn {
  margin-top: 1.5rem;
  border-radius: 0.1rem;
}
</style>
